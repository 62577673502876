div#react-jitsi-container , .call-container{
    width: auto;
    min-height: 100%;
    display: flex;
    flex-flow: column;
    justify-self: center;
    justify-items: stretch;
    background-color: #cacaca;

}
div#react-jitsi-frame{
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-self: center;
    justify-items: stretch;
    align-items: stretch;
    background-color: #9b9b9b;

}