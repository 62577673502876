.sidebar-container{
    padding: 10px;
}
.meeting-info-avatar {
    padding: 10px;
    justify-self: center;
    align-content: center;
}

.inspection-info-card{
    width: 100%;
}
.remediation-form {
}

.sidebar-container .list-group {
    max-height: 300px;
    overflow-y: scroll;
    padding-right: 40px;
    -webkit-overflow-scrolling: touch;
}